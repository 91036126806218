<template>
    <v-container>
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-0">
                <v-container>
                    <v-data-table v-if="this.status=='client'" :items="logins" :headers="loginHeaderClient"
                    :search="searchResult"
                    class="elevation-1"
                    dense>
                        <template v-slot:item.index="{item}">
                            {{ (logins.indexOf(item))+1 }}
                        </template>

                        

                        <template v-slot:top>
                            <v-container >
                                <v-toolbar flat class="">

                                    <v-select class="mx-2" dense outlined label="Month" :items="monthOptions" v-model="month" @change="fetch">
                                    </v-select>

                                    <v-select class="mx-2" dense outlined label="Years" :items="yearOptions" v-model="year" @change="fetch">
                                    </v-select>

                                    <v-text-field class="mx-2" filled dense outlined clearable v-model="searchResult" label="Search">
            
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>

                        

                    </v-data-table>

                    <v-data-table v-if="this.status=='internal'" :items="logins" :headers="loginHeaderInternal"
                    :search="searchResult"
                    class="elevation-1"
                    dense>
                    
                        <template v-slot:item.index="{item}">
                            {{ (logins.indexOf(item))+1 }}
                        </template>

                        <template v-slot:top>
                            <v-container >
                                <v-toolbar flat class="pa-3">

                                    <v-select class="mx-4" dense outlined label="Month" :items="monthOptions" v-model="month" @change="fetch">
                                    </v-select>

                                    <v-select class="mx-4" dense outlined label="Years" :items="yearOptions" v-model="year" @change="fetch">
                                    </v-select>

                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
            
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>



                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';



export default {
    computed: mapState({

    }),
    props:[
    'status'
    ],

    data:()=>({
        logins:null,
        searchResult:null,
        statusCheckedFalse :false,
        statusCheckedTrue:true,
        loginHeaderInternal:[
            {
                text:'No.',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'User',
                value:'user_name',
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile',
            },
            {
                text:'Total Login',
                value:'count',
            },
            {
                text:'Month',
                value:'month',
            },
            {
                text:'Year',
                value:'year'
            },
            {
                text:'Last Login',
                value:'login_at',
            },
                
        ],
        loginHeaderClient:[
            {
                text:'No.',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'User',
                value:'user_name',
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile',
            },
            {
                text:'Total Login',
                value:'count',
            },
            {
                text:'Month',
                value:'month',
            },
            {
                text:'Year',
                value:'year'
            },
            {
                text:'Last Login',
                value:'login_at',
            },
                
        ],
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        },
        yearOptions:['2021','2022','2023','2024'],
        year:'2024',
        monthOptions:[
            'January','February', 'March','April','May','June','July','August','September','October','November','December',
        ],
        // month:'August',
        month: new Date().toLocaleString('en', { month: 'long' }),
    }),

    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            this.logins = resp.logins;
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted(){
        this.fetch();
    },

    methods:{
        fetch(){
            let fetchLoginsApi = this.fetchLogins();
            this.$api.fetch(fetchLoginsApi);
        },
        fetchLogins(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/analysis/filterlogin?filter_year='+this.year+'&filter_month='+this.month+'&status='+this.status;
            return tempApi;
        },

    },
}
</script>